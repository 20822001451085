<script setup>
import { ref, inject, onMounted } from 'vue'
import Multiselect from '@vueform/multiselect'
import { sbapibackoffice } from '@/api'
import { navbarStore } from '@/stores/navbar'
import { spinnerStore } from '@/stores/spinner'
import Dialog from '../../components/Dialog.vue'
// require('dayjs/locale/it')
// import dayjs from 'dayjs'
// import { spinnerStore } from '@/stores/spinner'
// dayjs.locale('it')
// import { navbarStore } from '@/stores/navbar'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const toast = inject('$toast')
const spinner = spinnerStore()
const navbar = navbarStore()
const deleteDialog = ref(null)

const visibilities = ref([])
const thisSectionVisible = ref(false)

onMounted(async () => {
  navbar.title = 'Aziende Suggerite'
  spinner.show()

  try {
    let results = await sbapibackoffice.get('home/featured-companies')
    if (results?.data) {
      rows.value = results.data

      // set datepicker value
      for (let i = 0; i < rows.value.length; i++) {
        rows.value[i].date = [
          new Date(rows.value[i].publicationStartDate),
          new Date(rows.value[i].publicationEndDate)
        ]

        // set edited to false
        rows.value[i].edited = false

        rows.value[i].company.label = rows.value[i].company.coid + ' - ' + rows.value[i].company.name
      }
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  try {
    let results = await sbapibackoffice.get('home/sections-visibility')
    if (results?.data) {
      visibilities.value = results.data
      thisSectionVisible.value = results.data.find(v => v.sectionName == 'FEATURED_COMPANIES').visible
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }

  spinner.hide()
})

const rows = ref([])

const dragStart = (event, id) => {
  // Store the dragged ID when drag starts
  draggedId.value = id
}

const dragOver = (event) => {
  event.preventDefault() // Prevent the default behavior to allow drop
}

const draggedId = ref(null)

const drop = async (event, dropId) => {
  event.preventDefault()

  const draggedBox = rows.value.find(box => box.id === draggedId.value)
  const droppedBox = rows.value.find(box => box.id === dropId)

  if (draggedBox && droppedBox) {
    // Swap the diplayOrder values of the dragged and dropped elements
    const tempOrder = draggedBox.diplayOrder
    draggedBox.diplayOrder = droppedBox.diplayOrder
    droppedBox.diplayOrder = tempOrder

    try {
      const response = await sbapibackoffice.put('home/featured-companies/update-display-order', [{
        'id': draggedBox.id,
        'displayOrder': draggedBox.diplayOrder
      },
      {
        'id': droppedBox.id,
        'displayOrder': droppedBox.diplayOrder
      }])

      if (response?.data) {
        toast.success('Azienda riordinata con successo')
      }
    } catch (error) {
      toast.error('Errore durante il riordinamento dell\'azienda: ' + error.message)
    }
  }
  
  draggedId.value = null
}

const newRow = () => {
  // if a new box is already present, don't add another one
  if (rows.value.some((row) => !row.id)) {
    toast.warning('Completa l\'azienda corrente prima di crearne una nuova')
    return
  }
  rows.value.push({})
}

const saveRow = async (box) => {
  // check if all required fields are filled
  if (!box.company.coid || !box.date || !box.link) {
    toast.error('Compila tutti i campi obbligatori')
    return
  }

  // chack link url format
  if (!box.link.match(/^(http|https):\/\/[^ "]+$/)) {
    toast.error('Formato url errato. Utilizzare questo formato: https://ilmiosito.it')
    return
  }

  spinner.show()

  // diferentiate between new and existing box

  if (box.id) {
    // update existing box
    await updateBox(box)
  } else {
    // create new box
    await createBox(box)
  }

  spinner.hide()
}

const createBox = async (box) => {
  try {
    const response = await sbapibackoffice.post('home/featured-companies', {
      'coid': box.company.coid,
      'link': box.link,
      'publicationStartDate': box.date[0],
      'publicationEndDate': box.date[1]
    })

    if (response?.data) {
      box.id = response.data.id
      box.edited = false
      box.displayOrder = response.data.displayOrder
      toast.success('Azienda creata con successo e aggiunta in fondo alla lista')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const updateBox = async (box) => {

  try {
    const response = await sbapibackoffice.put('home/featured-companies/' + box.id, {
      'link': box.link,
      'publicationStartDate': box.date[0],
      'publicationEndDate': box.date[1]
    })

    if (response?.data) {
      box.edited = false
      toast.success('Azienda aggiornata con successo')
    }
  } catch (error) {
    switch (error.response.data.messageCode) {
      case 'BAN-002':
        toast.error('Errore: Il coid deve essere valorizzato')
        break
      case 'BAN-003':
        toast.error('Errore: Non esiste un\'azienda associata al coid in input: ' + box.company.coid)
        break
      case 'BAN-004':
        toast.error('Errore: Il campo link deve essere valorizzato')
        break
      case 'BAN-005':
        toast.error('Errore: il file media desktop deve essere valorizzato')
        break
      case 'BAN-006':
        toast.error('Errore: il file media mobile deve essere valorizzato')
        break
      case 'BAN-007':
        toast.error('Errore: Il campo seo text deve essere valorizzato')
        break
      case 'BAN-008':
        toast.error('Errore: La data di fine pubblicazione deve essere successiva a quella di inizio pubblicazione')
        break
      case 'BAN-009':
        toast.error('Errore: La durata della slide non può essere di 0 secondi')
        break
      case 'BAN-011':
        toast.error('Errore: La data di inizio pubblicazione deve essere valorizzata')
        break
      case 'BAN-012':
        toast.error('Errore: La data di fine pubblicazione deve essere valorizzata')
        break
      default:
        toast.error('Errore durante la creazione del box: ' + error.message)
    }

  }

  spinner.hide()
  
}

const rowToBeRemoved = ref(null)
const askRemovePermission = (box) => {
  deleteDialog.value.open()
  rowToBeRemoved.value = box
}

const removeRow = async () => {
  spinner.show()

  try {
    let results = await sbapibackoffice.delete('home/featured-companies/' + rowToBeRemoved.value.id)
    if (results?.data) {
      rows.value = rows.value.filter((row) => row.id !== rowToBeRemoved.value.id)
      deleteDialog.value.close()
      toast.success('Azienda eliminata con successo')
    }
  } catch (error) {
    toast.error('Errore durante l\'eliminazione dell\'azienda: ' + error.message)
  }

  spinner.hide()
}

const boxToUpdate = ref(null)

const uploadDesktopImage = (event) => {
  const file = event.target.files[0]
  const reader = new FileReader()

  reader.onload = (e) => {
    boxToUpdate.value.linkMediaDesktop = e.target.result
    boxToUpdate.value.edited = true
    boxToUpdate.value.fileMediaDesktop_changed = true
  }

  reader.readAsDataURL(file)

  // reset the input value
  event.target.value = ''
}

const noResults = ref(false)
const searchCompany = async (query) => {
  noResults.value = false

  try {
    if (query) {
      let results = await sbapibackoffice.get('companieslist?initialLettersOfCompanies=' + query)
      if (results?.data[0]?.coid) {
        results.data.forEach(company => {
          company.name = company.co_name
          company.label = company.coid + ' - ' + company.co_name
          delete company.co_name
        })
        return results.data
      }
      noResults.value = true
      return []
    }
    return []
  } catch (error) {
    toast.error('Errore durante la ricerca dell\'azienda: ' + error.message)
    noResults.value = true
    return []
  }
}

const toggleVisibility = async (event) => {
  try {
    const sectionId = visibilities.value.find(v => v.sectionName == 'FEATURED_COMPANIES').id
    await sbapibackoffice.put(`home/sections-visibility/${sectionId}`, { visible: event.target.checked })

    if (event.target.checked) {
      toast.success('Sezione visibile su cving.com')
    } else {
      toast.success('Sezione nascosta su cving.com')
    }
  } catch (error) {
    toast.error('Errore: ' + error.message)
  }
}

const onCompanySelected = (box, company) => {
  box.company = company
  box.edited = true
}
</script>

<template>
  <div class="container">
    <div class="cms_description">
      <p>Sezione dedicata ai box aziende presenti in Homepage.</p>
      <p>I campi<span class="required_symbol">*</span> sono obbligatori</p>
      <!-- switch toggle to hide show the entire cms section -->

      <div class="actions">
        <div class="switch-toggle">
          <input id="switch" v-model="thisSectionVisible" type="checkbox" @change="toggleVisibility">
          <label for="switch">Visibile su cving.com</label>
        </div>
        <div>
          <material-button text="Crea nuova azienda" type="success" @click="newRow" />
        </div>
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th><!-- drag icon --></th>
            <th>Azienda<span class="required_symbol">*</span></th>
            <th>Data<span class="required_symbol">*</span></th>
            <th style="text-align: center;">
              <p>Link<span class="required_symbol">*</span></p>
              <p>(Formato: https://ilmiosito.it)</p>
            </th>
            <th><!-- trash icon --></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="row in rows.sort((a, b) => a.diplayOrder - b.diplayOrder)"
            :key="row.id"
            draggable="true"
            @dragstart="dragStart($event, row.id)"
            @dragover="dragOver($event)"
            @drop="drop($event, row.id)"  
          >
            <td class="iconColumn">
              <font-awesome-icon
                class="dragItem"
                icon="grip-vertical"
                style="pointer-events: none;"
              />
            </td>
            <td>
              <div class="company_column">
                <Multiselect
                  :key="row.company ? 'azienda_coid_' + row.company.coid : 'no_selection'"
                  v-model="row.company"
                  placeholder="Cerca l'azienda"
                  :object="true"
                  :filter-results="false"
                  :min-chars="1"
                  :resolve-on-load="false"
                  :delay="250"
                  :searchable="true"
                  label="label"
                  track-by="coid"
                  value-prop="coid"
                  :clear-on-select="true"
                  :no-options-text="noResults ? 'Nessuna azienda corrisponde alla ricerca effettuata' : 'Inizia a digitare...'"
                  :options="async (query) => await searchCompany(query)"
                  @select="onCompanySelected(box, $event)"
                />
              </div>
            </td>
            <td class="date_column">
              <Datepicker
                :model-value="row.date"
                range
                multi-calendars
                auto-apply
                :enable-time-picker="false"
                :clearable="false"
                format="dd/MM/yyyy"
                @update:model-value="row.edited = true; row.date = $event"
              />
            </td>
            <td>
              <div class="text_column">
                <input v-model="row.link" type="text" @change="row.edited = true">
              </div>
            </td>
            <td class="iconColumn">
              <font-awesome-icon v-if="row.edited" class="saveRow" icon="floppy-disk" @click="saveRow(row)" />
              <font-awesome-icon
                v-else
                class="deleteItem"
                icon="trash-can"
                @click="askRemovePermission(row)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <input id="fileInputDesktop" type="file" style="display: none" accept="image/*" @change="uploadDesktopImage">

  <Dialog ref="deleteDialog" title="Vuoi Eliminare?" subtitle="se confermi, il box verrà eliminato" button-text="Conferma" @buttonAction="removeRow" />
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped lang="scss">
.container { padding: 20px; }

.cms_description {
  color: white;
  font-size: 1rem;
  
  p {
    margin-bottom: 10px;

    font-style: italic;
  }
  
  .actions {
    display: flex;
    justify-content: space-between;
    
    .switch-toggle {
      margin-top: 15px;
      margin-bottom: 30px;
      
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

// .required_symbol {
//   color: red;
//   font-size: 1.5rem;
// }

.table {
  color: white;
  height: calc(100vh - 225px);
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      background-color: #1a74a3;
      position: sticky;
      top: 0;
      z-index: 100;

      th {
        padding: 10px;
        text-align: left;
        
        &:not(:first-child):not(:last-child) {
          min-width: 200px;

          &.number_column {
            width: auto;
            min-width: 82px;
            max-width: 82px;
          }

          &.date_column {
            min-width: 260px;
          }
        }
      }
    }
    tbody {
      user-select: none;
      border-radius: 14px;
      tr {
        border-right: 1px solid #f4f4f438;
        border-left: 1px solid #f4f4f438;
        
        td {
          padding: 20px 10px;
          border-bottom: 1px solid #f4f4f438;
          border-radius: 14px;

          &.iconColumn {
            width: 35px;
          }
          
          input {
            color: white;
          }

          .deleteItem {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(253, 102, 102);
          }

          .saveRow {
            cursor: pointer;
            width: 16px;
            height: auto;
            color: rgb(102, 253, 102);
          }

          .dragItem {
            cursor: grab;
            height: auto;
            width: 22px;
          }

          .company_column {
            min-width: 200px;
          }

          .text_column {
            width: auto;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.file_column {
            width: auto;
            padding: 0 10px;

            .image-wrapper {
              position:relative;
              width: fit-content;

              img {
                max-width: 300px;
              }
            }

            .add-file {
              max-width: 130px;
              min-width: unset !important;
              overflow: visible !important;
              white-space: normal;
              line-height: unset !important;
              font-size: 10.5px;
              background: var(--info);
              color: white;
              text-transform: uppercase;
              outline: 0;
              box-shadow: none;
              border-radius: 4px;
              border: 2px solid var(--info);
              height: 41px;
              padding: 0 10px;
            }

            .delete-item-image {
              position: absolute;
              top: 5px;
              right: 5px;
              cursor: pointer;
              width: 16px;
              height: auto;
              color: rgb(253, 102, 102);
            }
          }

          &.number_column {
            width: auto;
            max-width: 82px;

            input {
              width: 100%;
              background: transparent;
              border-radius: 4px;
              padding: 12px;
              outline: none;
              font-size: 14px;
              border: 1px solid #d1d5db;
            }
          }

          &.date_column {
            width: 260px;
          }
        }
      }
    }
  }
}

// hide number input arrows
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

<style>
.date_column input {
  background-color: transparent;
  color: white;
  height: 41px;
}
</style>